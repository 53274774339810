<template>
	<template-base class="containerCard">
		<div>
			<div class="container-head">
				<div
					v-if="possuiPermissao('GER_V_ASSINATURAS')"
					class="container-add-new"
					v-bind:class="!subscriptionsType.length ? 'container-add-new-100' : ''"
				>
					<b-button class="add-button" @click="mostrarEdicao({})">
						Adicionar
					</b-button>
				</div>
			</div>
			<div v-if="subscriptionsType.length" class="head-row mb-2 width-pagination-items">
				<div class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + subscriptionsType.length }} de {{ pagination.count }}
					</label>
					<b-pagination
						class="mr-0 mb-2"
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
				</div>
				<b-form
					class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Itens por página
					</label>
					<b-form-select
						class="mr-0 mb-2"
						v-model="pagination.limit.value"
						name="itens-por-pagina"
						:options="optionsItensPorPag"
						@input="changeItensByPage()"
						:disabled="loading"
					/>
				</b-form>
			</div>
			<b-modal
				ref="modalEdicao"
				hide-footer
				:title="titleModalEdicao"
				size="md"
			>
				<div class="modal-edicao mb-5 modal-edicao-label">
					<b-row class="mt-3">
						<b-col class="text-parcela">
							<span class="label-conta">Descrição</span>
							<b-form-input
								name="description"
								class="modal-edicao-input"
								type="text"
								v-model="tipoAssinaturaSelecionada.description"
								autocomplete="off"
								placeholder="Digite a descrição"
								v-validate="{ required: true }"
								:state="validateState('description')"
							/>
						</b-col>
					</b-row>
					<b-row class="mt-3 filters-orientation">
						<b-col class="text-parcela">
							<span class="label-conta">Meses a ativar</span>
							<b-form-input
								name="monthsActive"
								class="modal-edicao-input number-class"
								type="text"
                                inputmode="numeric"
								v-model="tipoAssinaturaSelecionada.monthsActive"
								autocomplete="off"
								placeholder=""
								v-validate="{ required: true }"
								:state="validateState('monthsActive')"
                                @input="updateValue('monthsActive')"
							/>
						</b-col>
                        <b-col class="text-parcela">
							<span class="label-conta">Valor</span>
							<b-form-input
								name="amount"
								class="modal-edicao-input number-class"
								type="text"
                                inputmode="numeric"
								v-model="tipoAssinaturaSelecionada.amount"
								autocomplete="off"
								placeholder=""
								v-validate="{ required: true }"
								:state="validateState('amount')"
                                @input="updateValue('amount')"
							/>
						</b-col>
                        <b-col
							class="text-parcela">
							<div class="toggle-div">
								<span class="label-conta">Bloqueio</span>
								<ToggleSwitch
									name="blockPermissions"
									class="toggle"
									:value="tipoAssinaturaSelecionada.blockPermissions || false"
									@toggle="_ => changeToggle('blockPermissions')"
								/>
							</div>
						</b-col>
					</b-row>
				</div>
				<b-button class="ml-1 mt-5 float-left btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
				<b-button class="mt-5 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
				<b-button
					class="mt-5 mr-1 float-right btn-modal btn-salvar"
					@click="salvarEdicao"
					:disabled="invalidForm"
				>
					Salvar
				</b-button>
			</b-modal>
		</div>
		<div v-if="subscriptionsType.length" class="table-responsive">
			<DataTable v-if="!isMobile"
				class="data-table"
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:nosearch="true"
				:nofilters="true"
				name="usuarios"
				:hasPagination="true"
				:noedit="true"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_ASSINATURAS')"
				:permissaoDelete="possuiPermissao('GER_D_ASSINATURAS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				@clickSortTableBy="(v) => sortTableBy(v)"
				:state="{
					sortBy: sortTable.sortBy,
					sortAsc: sortTable.order === 1,
					query: ''
				}"
				:async="true"
                :alignColumns="detailsAlignCols"
			/>
			<CardTransaction v-else
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_ASSINATURAS')"
				:permissaoDelete="possuiPermissao('GER_D_ASSINATURAS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				:selectItem="false"
				:globalAcoes="false"
				@clickCheck="false"
				:colsDataCard="colsDataCard"
				:details="false"
				:showSelectItem="false"
			/>
		</div>
		<div v-else class="alert alert-warning" role="alert">
			{{ errMsg }}
		</div>
	</template-base>
</template>

<script>
	import DataTable from "@/components/DataTable";
	import TemplateBase from "@/templates/Base";

	import { possuiPermissao } from "../../helpers/permissions";
	import { SubscriptionsService } from "../../services/subscriptions";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import ToggleSwitch from "@/components/ToggleSwitch";
	import { numberFormatted, validateNumber, stringToNumber } from "@/helpers/common";
	import CardTransaction from "@/components/CardTransaction.vue";

	export default {
		components: {
			TemplateBase,
			DataTable,
			ToggleSwitch,
			CardTransaction
		},

		inject: ["parentValidator"],

		data () {
			return {
				subscriptionsType: [],
				linhas: [],
				titleModalEdicao: "",
				loading: true,
				tipoAssinaturaSelecionada: {
					idSubscriptionType: "",
					description: "",
					amount: "0,00",
					blockPermissions: false,
					monthsActive: "1"
				},

				subscriptionsService: new SubscriptionsService(),

				errMsg: "Nenhum tipo de assinatura cadastrada!",
				colunas: [
					"Descrição",
					"Meses",
					"Valor",
					"Bloqueio"
				],
				detailsAlignCols: [
					"letf",
					"center",
					"right",
					"center"
				],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				invalidForm: true,
				noEdit: false,
				possuiPermissao,
				sortTable: {
					sortBy: 0,
					order: -1
				},
				isMobile: false,
				colsDataCard: {
					0: {
						idx: 0,
						label: "Descrição",
						textAlign: "left",
						cols: 12,
						visible: true
					},
					1: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					},
					2: {
						idx: null,
						label: "",
						textAlign: "center",
						cols: 3,
						visible: false
					},
					3: {
						idx: 1,
						label: "Meses",
						textAlign: "center",
						cols: 4,
						visible: true
					},
					4: {
						idx: 2,
						label: "Valor",
						textAlign: "right",
						cols: 4,
						visible: true
					},
					5: {
						idx: 3,
						label: "Bloqueio",
						textAlign: "center",
						cols: 4,
						visible: true
					},
					6: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 6,
						visible: false
					},
					7: {
						idx: null,
						label: "",
						textAlign: "r",
						cols: 6,
						visible: false
					}
				},
				config: {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				}
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);

			this.findSubscriptionsType();
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
			},

			sortTableBy (v) {
				this.sortTable = v;
				this.findSubscriptionsType();
			},


			changePage () {
				this.pagination.page = this.currentPage;
				this.findSubscriptionsType();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findSubscriptionsType();
			},

			async findSubscriptionsType () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;
				const result = await this.subscriptionsService.listSubscriptionsType({ offset, limit, sortTable }) || {};
				this.subscriptionsType = result.rows || [];

				this.linhas = result.rows.reduce((acc, st) => [
					...acc,
					{
						idSubscriptionType: st.idSubscriptionType,
						cols: [
							st.description,
							st.monthsActive,
							st.amount ? numberFormatted(st.amount) : "0,00",
							st.blockPermissions ? "Sim" : "Não"
						]
					}
				], []);
				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			async updateValue (key) {
				const value = this.tipoAssinaturaSelecionada[key];
				this.tipoAssinaturaSelecionada[key] = await validateNumber(value);
			},

			mostrarEdicao (item_) {
				this.invalidForm = true;
				let item = {};
				if (item_.idSubscriptionType)
					[ item ] = this.subscriptionsType.filter((s) => s.idSubscriptionType === item_.idSubscriptionType);

				const { idSubscriptionType, description, subscriptionType, amount, blockPermissions, monthsActive } = item;

				if (!idSubscriptionType) {
					this.titleModalEdicao = "Cadastrar Novo Tipo de Assinatura";
					this.noEdit = true;
				} else {
					this.titleModalEdicao = "Editar Assinatura";
					this.noEdit = false;
				}
				this.tipoAssinaturaSelecionada.idSubscriptionType = idSubscriptionType || "";
				this.tipoAssinaturaSelecionada.description = description || "";
				this.tipoAssinaturaSelecionada.amount = numberFormatted(amount || 0);
				this.tipoAssinaturaSelecionada.blockPermissions = blockPermissions;
				this.tipoAssinaturaSelecionada.monthsActive = monthsActive || "1";
				this.$refs.modalEdicao.show();
			},

			limparModal () {
				this.tipoAssinaturaSelecionada.description = "";
				this.tipoAssinaturaSelecionada.amount = "0,00";
				this.tipoAssinaturaSelecionada.blockPermissions = false;
				this.tipoAssinaturaSelecionada.monthsActive = "1";
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
				this.limparModal();
				this.tipoAssinaturaSelecionada.idSubscriptionType = "";
			},

			async salvarEdicao () {
				const invalidFields = await this.validateModal();
				if (invalidFields) {
					await this.$swal({
						title: "Formulário Inválido",
						text: "Existe informação sem preencher!",
						type: "error",
						showCancelButton: false,
						confirmButtonColor: "#DD6B55",
						//imageWidth: 50,
						//imageHeight: 50,
						confirmButtonText: "OK",
						reverseButtons: true
					});
					return;
				}

				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				const payload = {
					idSubscriptionType: this.tipoAssinaturaSelecionada.idSubscriptionType,
					description: this.tipoAssinaturaSelecionada.description,
					amount: stringToNumber(this.tipoAssinaturaSelecionada.amount),
					blockPermissions: this.tipoAssinaturaSelecionada.blockPermissions,
					monthsActive: this.tipoAssinaturaSelecionada.monthsActive
				};

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.subscriptionsService.upsertSubscriptionType({payload});

						this.fecharModal("modalEdicao");
						this.findSubscriptionsType();

						return {
							title: "Sucesso!",
							body: "Tipo de assinatura salva com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar tipo de assinatura!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},







			async confirmaApagar (item_) {
				const [ item ] = this.subscriptionsType.filter((s) => s.idSubscriptionType === item_.idSubscriptionType);
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja remover o tipo de assinatura?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idSubscriptionType);
			},

			async apagar (idSubscriptionType) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						await this.subscriptionsService.deleteSubscriptionType({ idSubscriptionType });
						this.findSubscriptionsType();

						return {
							title: "Sucesso!",
							body: "Tipo de assinatura deletado com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar o tipo de assinatura!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			},

			async validateModal () {
				const validationFields = Object.keys(this.veeFields);
				if (!validationFields.length)
					return true;

				const validations = validationFields.reduce((acc, ref) => {
					if (this.veeFields[ref] && this.veeFields[ref].invalid)
						return [...acc, this.veeFields[ref].invalid];

					return acc;
				}, []);

				const invalidFields = validations.filter(field => field);
				return Boolean(invalidFields && invalidFields.length);
			},

			changeToggle (key) {
				this.tipoAssinaturaSelecionada[key] = !this.tipoAssinaturaSelecionada[key];
			}
		}
	};
</script>

<style scoped>
	.number-class {
		text-align: right;
	}

	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 5rem;
		width: 100%;
	}

	.container-pages {
		display: flex;
		width: 70%;
		margin-top: 1.5rem;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.modal-edicao-label {
		font-family: "Roboto Condensed Regular";
		font-size: 23px;
		letter-spacing: -0.05em;
	}

	.modal-edicao-input {
		font-family: "Roboto Condensed Regular";
		font-size: 20px;
		letter-spacing: -0.05em;
	}

	.table-body-detalhe {
		padding: 5px 0;
	}

	.container-add-new-100 {
		width: 100% !important;
	}

	.toggle {
		margin-top: 7px;
	}

	.toggle-div {
		width: 100%;
		text-align: center;
	}
</style>

<style>
	.form-control {
		height: auto !important;
	}
</style>
